<template>
  <v-list
    class="py-5 rounded-md"
    min-width="230"
    style="border: 1px solid var(--v-secondary-base);"
  >
    <!-- <v-list-item to="/profile/profile-update"> -->
    <v-list-item :to="{ name: 'ProfileUpdate' }">
      <v-list-item-title>{{ $t("profile.menu.profile") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item :to="{ name: 'Orders' }">
      <v-list-item-title>{{ $t("profile.menu.orders") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <!-- <v-list-item to="/profile/lists"> -->
    <v-list-item :to="{ name: 'Lists' }">
      <v-list-item-title>{{ $t("profile.menu.lists") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item :to="{ name: 'Addresses' }">
      <v-list-item-title>I miei indirizzi</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item :to="{ name: 'MyPaymentMethods' }">
      <v-list-item-title>I miei metodi di pagamento</v-list-item-title>
    </v-list-item>

    <!-- <v-divider /> -->
    <!-- <v-list-item to="/profile/news-list">
                  <v-list-item-title>I miei messaggi</v-list-item-title>
                </v-list-item> -->
    <v-list-item @click.stop="doLogout">
      <v-btn block outlined color="secondary">{{
        $t("profile.menu.logout")
      }}</v-btn>
    </v-list-item>
  </v-list>
</template>
<style scoped lang="scss">
.v-list-item {
  padding: 0px 16px;
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    doLogout() {
      this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  }
};
</script>

<template>
  <v-app-bar
    app
    flat
    class="appbar"
    clipped-left
    :class="classCordova"
    v-resize="onResize"
  >
    <v-container fluid class="d-flex flex-column pa-0">
      <!-- primary navbar -->
      <v-row
        no-gutters
        class="primary-navbar white py-1 py-sm-2 px-1"
        :align="'center'"
      >
        <!-- menu category tree mobile -->
        <v-col
          cols="4"
          sm="4"
          md="0"
          lg="0"
          xl="0"
          class="menu-btn-mobile order-0 d-block d-md-none justify-center align-center"
        >
          <v-btn
            text
            class="primary"
            @click.prevent.stop="drawerLeft = !drawerLeft"
          >
            <v-icon color="white">$menu</v-icon>
            <!-- <span
              class="white--text ml-1 text-left"
              v-html="$t('navbar.menuBtnMobile')"
            ></span> -->
          </v-btn>
        </v-col>
        <!-- blocco logo -->
        <v-col
          cols="3"
          sm="3"
          md="3"
          lg="2"
          xl="2"
          class="order-1 d-flex justify-center justify-md-start align-center"
        >
          <!-- logo desktop -->
          <router-link to="/" class="px-0">
            <v-img
              class="logo logo-desktop"
              eager
              src="@/assets/tosano.svg"
              alt="Logo Tosano"
              height="90"
              width="240"
              contain
            />
          </router-link>

          <!-- logo mobile -->
          <router-link to="/" class="px-0">
            <v-img
              class="logo-mobile"
              src="@/assets/tosano.svg"
              alt="Logo Tosano"
              height="59px"
              width="120px"
              contain
            />
          </router-link>
        </v-col>
        <!-- blocco ricerca -->
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          class="order-3 order-md-2 d-sm-flex flex-sm-row justify-sm-center align-sm-center pl-md-8 d-md-flex flex-md-row justify-md-center align-md-center "
          style="flex-wrap: nowrap;"
        >
          <div
            style="min-width: 100px; max-width: 100%;"
            class="flex-grow-1 flex-shrink-0 pl-0"
          >
            <SearchInput />
          </div>
        </v-col>
        <!-- blocco pulsanti -->
        <v-col
          cols="5"
          sm="5"
          md="5"
          lg="6"
          xl="6"
          class="order-2 order-md-3 d-flex align-end flex-column"
        >
          <!-- sloggato -->
          <v-row
            no-gutters
            v-if="!isAuthenticated"
            justify="end"
            align="center"
          >
            <v-btn @click.stop="handleLogin()" large icon>
              <v-icon color="secondary">$profile</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              depressed
              class="ml-4"
              @click.stop="handleLogin()"
            >
              <v-icon>$cart</v-icon>
              <span class="d-none d-sm-inline font-weight-normal ml-4">{{
                $n(0, "currency")
              }}</span>
            </v-btn>
          </v-row>
          <!-- loggato -->
          <v-row
            no-gutters
            v-else
            justify="end"
            align="center"
            class="d-flex flex-nowrap"
          >
            <div class="d-flex align-center mr-0 mr-sm-2 mr-lg-6">
              <v-btn
                class="d-none d-md-inline-flex"
                @click.stop="openAddressSelector"
                large
                icon
              >
                <v-icon color="secondary">$storefront</v-icon>
              </v-btn>
              <v-btn
                class="ml-2 d-none d-sm-inline-flex"
                @click.stop="openTimeslotSelector"
                large
                icon
              >
                <v-icon color="secondary">$editcalendar</v-icon>
              </v-btn>
              <v-menu class="ml-2" bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-2" v-bind="attrs" v-on.stop="on" large icon>
                    <v-icon color="secondary">$profile</v-icon>
                  </v-btn>
                </template>
                <UserMenu />
              </v-menu>
              <span
                class="username d-none d-md-inline text-caption default--text pl-0"
                v-html="
                  $t('navbar.userMessage', { firstName: cart.user.firstName })
                "
              ></span>
            </div>
            <div class="d-flex align-center rounded-lg border ml-2">
              <v-btn
                color="primary"
                depressed
                @click.stop="openCart()"
                class="no-border"
              >
                <v-badge
                  :content="totalItems"
                  :value="totalItems > 0"
                  :key="totalItems"
                  transition="bounce"
                  color="secondary"
                  overlap
                  offset-x="10"
                  offset-y="16"
                >
                  <v-icon>
                    $cart
                  </v-icon>
                </v-badge>
                <span
                  class="navbar-gross-total d-none d-sm-inline font-weight-normal ml-6"
                  >{{ $n(cart.totalPrice, "currency") }}</span
                >
              </v-btn>
            </div>
          </v-row>
        </v-col>
        <!-- <v-col cols="1" sm="2" class="d-flex d-md-none order-12 justify-end">
          <v-btn
            icon
            large
            class="no-border"
            @click.stop="drawerLinks = !drawerLinks"
            style="right: -5px"
          >
            <v-icon large color="primary">$dots</v-icon>
          </v-btn>
        </v-col> -->
      </v-row>
      <div class="d-flex delivery-fee-container">
        <DeliveryFee
          :showList="false"
          :deliveryFee="deliveryFee"
          :optionClass="'navbar-delivery-fee'"
        />
      </div>
      <!-- secondary navbar -->
      <v-row
        no-gutters
        class="d-flex flex-nowrap links align-center secondary"
        :class="{ 'bar-shadow': barShadow && isMobile }"
        style="height: 40px"
        :key="componentKey"
      >
        <!-- class="d-none d-md-flex links align-center secondary" -->
        <div>
          <v-btn
            @click.prevent.stop="drawerLeft = !drawerLeft"
            depressed
            height="40"
            tile
            class="d-none d-md-inline-flex font-weight-normal menubtn"
            color="primary"
          >
            <v-icon medium class="mr-2">$menu</v-icon>
            {{ $t("navbar.menuBtn") }}
          </v-btn>
        </div>
        <ProposalButtonsGrid :proposals="header" />
      </v-row>
    </v-container>
    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>
<style lang="scss">
.delivery-fee-container {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    top: 72px;
    right: 15px;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-direction: row-reverse;
    padding: 0px 10px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .delivery-fee {
      width: 100%;
      text-align: center;
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .primary-navbar {
    height: 94px;
  }
}
.v-app-bar--fixed {
  background-color: #fcfcfc !important;
  position: fixed;
  top: 0;
  z-index: 11 !important;
  width: 100%;
  // iOS 11.0 fixes
  height: calc(
    #{$statusbarheight} + constant(safe-area-inset-top) + 14px
  ) !important; // % ($statusbarheight constant(safe-area-inset-top))
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  // iOS 11.2 fixes
  height: calc(
    #{$statusbarheight} + env(safe-area-inset-top) + 14px
  ) !important; // ($statusbarheight env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-app-bar--fixed {
    position: fixed;
    top: 0;
    z-index: 11 !important;
    height: $statusbarheightxs;
    width: 100%;
    // iOS 11.0 fixes
    height: calc(
      #{$statusbarheightxs} + constant(safe-area-inset-top) + 14px
    ) !important;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    // iOS 11.2 fixes
    height: calc(
      #{$statusbarheightxs} + env(safe-area-inset-top) + 14px
    ) !important;
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}
.logo-desktop {
  margin-left: 30px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .logo-desktop {
    display: none;
  }
  .logo-mobile {
    display: flex;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .logo-desktop {
    display: flex;
  }
  .logo-mobile {
    display: none;
  }
}
.appbar {
  height: fit-content !important;
  // box-shadow: none !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    border-top: none;
  }
  .v-badge__badge {
    font-size: 10px;
    font-weight: normal;
    min-width: 20px;
    padding: 5px 1px;
    text-align: center;
  }
  .navbar-gross-total {
    font-size: 14px;
  }
  .menu-btn-mobile {
    .v-btn.v-size--default {
      padding: 0px 10px;
    }
    .v-btn .v-btn__content {
      line-height: 1.1;
      letter-spacing: 0;
    }
  }

  .links {
    transform: translateY(0);
    transition: transform 300ms linear;
    &.bar-shadow {
      // transform: translateY(-100%);
      -webkit-box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
      box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
    }
  }
}
.v-navigation-drawer--fixed {
  position: fixed;
  z-index: 11;
  height: $navigationheight;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  // iOS 11.2 fixes
  height: calc(
    #{$navigationheight} + env(safe-area-inset-top) + 5px
  ) !important; // ($navigationheight env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  &.categories {
    top: 134px !important;
    height: calc(100vh - 134px) !important;
  }
  &.cart-drawer {
    height: fit-content !important;
    max-height: calc(
      #{$navigationheight} + env(safe-area-inset-top) + 5px
    ) !important; // ($navigationheight env(safe-area-inset-top));
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    top: 0 !important;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    &.categories {
      top: 164px !important;
      height: calc(100vh - 164px) !important;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    &.categories {
      top: 148px !important;
      height: calc(100vh - 148px) !important;
    }
  }
}
.drawer-btn {
  height: 60px !important;
  width: 60px !important;
  label {
    font-size: 9px;
  }
}
.v-toolbar__content {
  height: auto !important;
  padding: 0px !important;
}
.menubtn {
  width: 266px;
  span {
    font-size: 14px;
  }
}
</style>
<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
import ProposalButtonsGrid from "@/components/proposal/ProposalButtonsGrid.vue";
import UserMenu from "@/components/navigation/UserMenu.vue";
import DeliveryFee from "@/components/delivery/DeliveryFee.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";

import CategoryService from "@/commons/service/categoryService";

import { mapActions, mapState } from "vuex";
import { mapProposal } from "~/service/ebsn.js";

export default {
  name: "Navbar",
  props: {
    deliveryFee: { type: Object, required: false },
    barShadow: { type: Boolean, default: false }
  },
  mixins: [login, cartInfo],
  components: {
    SearchInput,
    UserMenu,
    ProposalButtonsGrid,
    DeliveryFee
  },
  data() {
    return {
      componentKey: 0,
      // isMobile: false,
      navbarCategory: null
    };
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    goBack() {
      this.$router.go(-1);
    },
    onResize() {
      this.componentKey++;
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    async loadNavbarCategory() {
      try {
        this.navbarCategory = await CategoryService.getCategoryBySlug(
          "navbar",
          false
        );
      } catch (e) {
        console.log(e);
      }
    },
    historyBack() {
      window.history.back();
    },
    async logout() {
      await this.doLogout();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.drawers.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    ...mapProposal(
      {
        header: "header"
      },
      "navbarCategory"
    ),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment" || this.$route.name == "Checkout");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  },
  async created() {
    this.loadNavbarCategory();
  }
};
</script>
